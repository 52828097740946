import * as React from 'react';
import {useSelector} from "react-redux";
import {fetchBuilderSettingByKey} from "../../../../helpers/MethodHelper";
import Checkers from "../../../../helpers/class/Checkers";
import {useEffect} from "react";

type InlineStyleProps = {
    app: {
        designMode: boolean,
        designColor: string,
        designFontColor: string,
        searchbarBgColor: string
        designHeaderBgColor: string,
        builder: {
            builder_properties_bg_color: "",
            builder_properties_text_color: 1,
            builder_properties_view_type: 206,
            builder_properties_columns: 4,
            builder_properties_radius: 12,
            builder_properties_shadow: 0,
            builder_properties_border: 0,
            builder_header_color: ""
        }
        servicesRadius: number
        servicesShadow: number
    },
    rgbDesignColor: string,
    rgbSearchbarColor: string
}

export const InlineStyles: React.FC<InlineStyleProps> = ( props ) => {

    const builderSettingsData = useSelector(state => state['builderSettingsData']['settings']);

    const servicesHomepageRadious = props.app.servicesRadius

    const servicesHomepageShadow = props.app.servicesShadow

    const menuFontColor = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_header_menu_color_sticky')
    );

    const headerMenuColor = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_header_menu_color')
    );

    const disableUiControls = useSelector(
        state => Checkers.valueToBoolean(fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_propertylist_map_disable_controls'))
    );

    const customStyles = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_custom_css')
    );

    const menuIconBorder = useSelector(
        state => Checkers.valueToBoolean(fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_menu_icon_border'))
    );

    const builderPropertiesBorder = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_properties_border')
    );

    const builderPropertiesShadow = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_properties_shadow')
    );

    const builderPropertiesRadius = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_properties_radius')
    );

    const builderEstatesBorder = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_properties_estates_border')
    );

    const builderEstatesShadow = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_properties_estates_shadow')
    );

    const builderEstatesRadius = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_properties_estates_radius')
    );

    const builderBookingBoxBorder = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_booking_box_border')
    );

    const builderBookingBoxShadow = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_booking_box_shadow')
    );

    const builderBookingBoxRadius = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_booking_box_radius')
    );

    const builderPropertiesViewServicesBorder = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_propertyview_services_border')
    );

    const builderPropertiesViewServicesRadius = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_propertyview_services_radius')
    );

    const builderPropertiesViewServicesShadow = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_propertyview_services_shadow')
    );


    const builderDestinationsShadow = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_homepage_destinations_shadow')
    );


    const builderDestinationsBorder = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_homepage_destinations_border')
    );


    const builderDestinationsRadius = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_homepage_destinations_radius')
    );


    const builderCollectionsShadow = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_homepage_collections_shadow')
    );


    const builderCollectionsBorder = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_homepage_collections_border')
    );


    const builderCollectionsRadius = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_homepage_collections_radius')
    );


    const builderExperiencesBorder = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_homepage_experiences_border')
    );


    const builderExperiencesRadious = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_homepage_experiences_radius')
    );


    const builderExperiencesShadow = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_homepage_experiences_shadow')
    );


    const builderMenuSpacing = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_header_menu_spacing')
    );

    // const builderPropertiesBgColor = useSelector(state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_properties_bg_color'));

    // useEffect( () => {
    //     console.log("forDebug", "boxSetting[border]", builderPropertiesBorder)
    //     console.log("forDebug", "boxSetting[shadow]", builderPropertiesShadow)
    //     console.log("forDebug", "boxSetting[radius]", builderPropertiesRadius)
    //     console.log("forDebug", "boxSetting[bg-color]", builderPropertiesBgColor)
    // }, [])

    const getBuilderSettingByKey = (key) => {
        if ( builderSettingsData==null || builderSettingsData==undefined ) {
            return "#ffffff";
        }

        if ( builderSettingsData.builder==undefined || builderSettingsData.builder==null ) {
            return "#ffffff";
        }

        if ( builderSettingsData.builder[key]==undefined || builderSettingsData.builder[key]==null ) {
            return "#ffffff";
        }

        return builderSettingsData.builder[key]
    }
    // const propertiesTextColor = builderSettingsData!=undefined && builderSettingsData.builder.builder_properties_text_color!=undefined && builderSettingsData.builder.builder_properties_text_color.length>0 ? builderSettingsData.builder.builder_properties_text_color : null
    // const builder_properties_border = builderSettingsData.builder!=undefined && builderSettingsData.builder.builder_properties_border!=undefined && builderSettingsData.builder.builder_properties_border.length>0 ? builderSettingsData.builder.builder_properties_border : null

    // useEffect( () => {
    //     console.log("forDebug", "propertiesBackgroundColor", getBuilderSettingByKey('builder_properties_bg_color'))
    //     console.log("forDebug", "propertiesTextColor", getBuilderSettingByKey('builder_properties_text_color'))
    //     console.log("forDebug", "builder_properties_border", getBuilderSettingByKey('builder_properties_border'))
    //     console.log("forDebug", "builder_properties_shadow", getBuilderSettingByKey('builder_properties_shadow'))
    //     console.log("forDebug", "builder_properties_radius", getBuilderSettingByKey('builder_properties_radius'))
    // }, [builderSettingsData])

    const app = props.app;
    const rgbDesignColor = props.rgbDesignColor;
    const rgbSearchbarColor = props.rgbSearchbarColor;

    const initialiazeStyle = () => {
        // const head = document.getElementsByTagName('head')[0];
        // const style = document.createElement('style');
        // const link = document.createElement('link');
        // link.rel = 'stylesheet';
        // link.type = 'text/css';
        const style = {innerText: ''}
        // style.innerText = '';
        if (Checkers.isHexColor(menuFontColor)) {
            style.innerText +=
                `.Header.Header-Light-Transparent.Header-Sticky-Top-Not .navbar-nav .nav-link { color: ${menuFontColor} }`

            style.innerText +=
                `.Header.Header-Light-Transparent.Header-Sticky-Top-Not .navbar-nav .nav-link svg { color: ${menuFontColor} }`

            style.innerText +=
                `.Header.Header-Light-Transparent.Header-Sticky-Top-Not svg { color: ${menuFontColor} }`
        }
        if (app.designColor != null && app.designColor != '') {
            style.innerText +=
                `.navbar-design-color{background-color: ${app.designColor}!important}` +
                `.navbar-design-color.border-bottom{border-bottom: ${app.designColor}!important}` +
                `.main-design-color{color: ${app.designColor}!important}` +
                `div.cs-skin-slide > span:after, div.cs-skin-slide.cs-active > span:after{color: ${app.designColor}!important}` +
                `.nav-tab-dropdown .cs-select .cs-placeholder{color: ${app.designColor}!important;font-size: 13px; border-bottom: 1px solid ${app.designColor}; }` +
                `.nav-tabs-simple > li > a:after{background-color: ${app.designColor}!important}` +
                `.btn-design-color{background-color: ${app.designColor}!important;border: 1px solid ${app.designColor}!important;border-color: ${app.designColor}!important;${app.designMode ? 'color: #fff' : 'color: #000'}!important;}` +
                `.bg-design-color{background-color: ${app.designColor}!important;border-color: ${app.designColor}!important}` +
                `.text-design-color{color: ${app.designColor}!important}` +
                `.btn-bordered-design-color > *{${app.designMode ? 'color: #fff!important;' : 'color: #000!important;'}}` +
                `.btn-bordered-design-color:hover > *{color: ${app.designColor}!important;}` +
                `.btn-bordered-design-color{background: transparent!important;background-color: transparent!important; ${app.designMode ? `color: #fff!important;` : `color: #000!important;`} ${app.designMode ? 'border: 2px solid #fff!important;' : 'border: 2px solid #000!important;'}}` +
                `.btn-bordered-design-color:hover{${app.designMode ? 'background: #fff!important;background-color: #fff!important;' : 'background: #000!important;background-color: #000!important;'} color: ${app.designColor}!important; ${app.designMode ? 'border: 2px solid #fff!important;' : 'border: 2px solid #000!important;'}}`;
        }
        if (rgbDesignColor != 'bad_hex') {
            style.innerText +=
                `.btn-design-color:hover {background-color: rgba(${rgbDesignColor}, .85)!important;border-color: ${app.designColor}!important;${app.designMode ? 'color: #fff' : 'color: #000'}}` +
                `.btn-map.btn-design-color:hover{background-color: ${app.designColor}!important;border: 1px solid ${app.designColor}!important;border-color: ${app.designColor}!important;${app.designMode ? 'color: #fff' : 'color: #000'}}` +
                `.MuiDateRangePickerDay-rangeIntervalDayHighlight{background-color: rgba(${rgbDesignColor}, .5) !important}` +
                `.MuiPickersDay-root.Mui-selected{background-color: rgba(${rgbDesignColor}) !important;text-shadow: 0px 0px 2px #000}` +
                `.form-group-default.focused{color: #212529!important;box-shadow: 0 0 0 0.25rem rgba(${rgbDesignColor},.25)!important}`;
        } else {
            style.innerText +=
                `.btn-design-color:hover {background-color: ${app.designColor}!important;border-color: ${app.designColor}!important}`;
        }
        if (app.designHeaderBgColor != null && app.designHeaderBgColor != '') {
            style.innerText +=
                `.navbar-custom-color{background-color: ${app.designHeaderBgColor}!important}` +
                `.navbar-custom-color.border-bottom{border-bottom: ${app.designHeaderBgColor}!important}`;
        }
        if (app.builder.builder_header_color != null && app.builder.builder_header_color != '') {
            style.innerText +=
                `.Header-Custom-Color{background-color: ${app.builder.builder_header_color}!important}` +
                `.Header-Custom-Color.border-bottom{border-bottom: ${app.builder.builder_header_color}!important}`;
        }
        if (app.designFontColor != null && app.designFontColor != '') {
            style.innerText +=
                `.navbar-text-custom-color{color: ${app.designFontColor}!important}` +
                `.navbar-text-custom-color [class^="fa"]{color: ${app.designFontColor}!important}` +
                `.navbar-text-custom-color [class*="fa-"]{color: ${app.designFontColor}!important}` +
                `.navbar-text-custom-color .nav-item .nav-link{color: ${app.designFontColor}!important}` +
                `.navbar-text-custom-color .nav-item .nav-link [class^="fa"]{color: ${app.designFontColor}!important}` +
                `.navbar-text-custom-color .nav-item .nav-link [class*="fa-"]{color: ${app.designFontColor}!important}` +
                `.navbar-text-custom-color .nav-item .nav-link.btn{color: ${app.designFontColor}!important;background-color: transparent!important;border-color: ${app.designFontColor}!important}` +
                `.b-transparent-white.navbar-text-custom-color{color: ${app.designFontColor}!important;background-color: transparent!important;border-color: ${app.designFontColor}!important}`;
        }
        if (app.designHeaderBgColor != null && app.designHeaderBgColor != '') {
            style.innerText +=
                `.navbar-design-color{color: ${app.designHeaderBgColor}!important}`;
        }
        if (app.searchbarBgColor != null) {
            style.innerText +=
                `.searchbar-bg-color{background-color: ${app.searchbarBgColor}!important}` +
                `.btn-search-color{background-color: ${app.searchbarBgColor}!important;border-color: ${app.searchbarBgColor}!important}`;
        }
        if (rgbSearchbarColor != 'bad_hex') {
            style.innerText +=
                `.btn-search-color:hover{background-color: rgba(${rgbSearchbarColor}, .85) !important;border-color: ${app.searchbarBgColor}!important}` +
                `.MuiDateRangePickerDay-rangeIntervalDayHighlight{background-color: rgba(${rgbSearchbarColor}, .5) !important}` +
                `.MuiPickersDay-root.Mui-selected{background-color: rgba(${rgbSearchbarColor}) !important;text-shadow: 0px 0px 2px #000}`;
        } else {
            style.innerText +=
                `.btn-search-color:hover{background-color: ${app.searchbarBgColor}!important;border-color: ${app.searchbarBgColor}!important}`;
        }

        if ( builderPropertiesBorder!=null || builderPropertiesRadius!=null || builderPropertiesShadow!=null ) {
            style.innerText +=
                `.design-box {`

            style.innerText +=
                `overflow: hidden !important;`
            if ( builderPropertiesRadius!=null ){
                style.innerText +=
                    `border-radius: ${builderPropertiesRadius}px !important;`
                style.innerText +=
                    `-webkit-border-radius: ${builderPropertiesRadius}px !important;`
                style.innerText +=
                    `-khtml-border-radius: ${builderPropertiesRadius}px !important;`
                style.innerText +=
                    `-moz-border-radius: ${builderPropertiesRadius}px !important;`

                style.innerText +=
                    `border-top-left-radius: ${builderPropertiesRadius}px !important;`
                style.innerText +=
                    `-moz-border-top-left-radius: ${builderPropertiesRadius}px !important;`
                style.innerText +=
                    `-moz-border-top-right-radius: ${builderPropertiesRadius}px !important;`

                style.innerText +=
                    `border-top-right-radius: ${builderPropertiesRadius}px !important;`
                style.innerText +=
                    `-moz-border-right-left-radius: ${builderPropertiesRadius}px !important;`
                style.innerText +=
                    `-moz-border-right-right-radius: ${builderPropertiesRadius}px !important;`
            } else {
                style.innerText +=
                    `border-radius: 0px;`
            }
            if ( builderPropertiesShadow==1 ){
                style.innerText += `box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);-webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);-moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);`
            } else {
                style.innerText += `box-shadow: none;-webkit-box-shadow: none;-moz-box-shadow: none;`
            }
            if ( builderPropertiesBorder==1 ){
                style.innerText += `border: 1px solid #eeeeee;`
            } else {
                style.innerText += `border: none;`
            }
            style.innerText +=
                `}`


            style.innerText +=
                `.Properties.Properties-Thumb.Properties-Border.Properties-Shadow .Properties__gallery,
                .Properties.Properties-Thumb.Properties-Border.Properties-Shadow .Properties__gallery > div {`

            style.innerText +=
                `overflow: hidden !important;`
            if ( builderPropertiesRadius!=null ){
                style.innerText +=
                    `border-top-left-radius: ${builderPropertiesRadius}px !important;`
                style.innerText +=
                    `-moz-border-top-left-radius: ${builderPropertiesRadius}px !important;`
                style.innerText +=
                    `-moz-border-top-right-radius: ${builderPropertiesRadius}px !important;`

                style.innerText +=
                    `border-top-right-radius: ${builderPropertiesRadius}px !important;`
                style.innerText +=
                    `-moz-border-right-left-radius: ${builderPropertiesRadius}px !important;`
                style.innerText +=
                    `-moz-border-right-right-radius: ${builderPropertiesRadius}px !important;`
            } else {
                style.innerText +=
                    `border-radius: 0px;`
            }
            style.innerText +=
                `}`
            // border-radius: ${builderPropertiesRadius}px;box-shadow: ${builderPropertiesShadow};border: ${builderPropertiesBorder}px solid #eeeeee`
        }

        if ( builderEstatesBorder!=null || builderEstatesRadius!=null || builderEstatesShadow!=null ) {
            style.innerText +=
                `.design-box-estates {`

            style.innerText +=
                `overflow: hidden !important;`
            if ( builderEstatesRadius!=null ){
                style.innerText +=
                    `border-radius: ${builderEstatesRadius}px !important;`
                style.innerText +=
                    `-webkit-border-radius: ${builderEstatesRadius}px !important;`
                style.innerText +=
                    `-khtml-border-radius: ${builderEstatesRadius}px !important;`
                style.innerText +=
                    `-moz-border-radius: ${builderEstatesRadius}px !important;`

                style.innerText +=
                    `border-top-left-radius: ${builderEstatesRadius}px !important;`
                style.innerText +=
                    `-moz-border-top-left-radius: ${builderEstatesRadius}px !important;`
                style.innerText +=
                    `-moz-border-top-right-radius: ${builderEstatesRadius}px !important;`

                style.innerText +=
                    `border-top-right-radius: ${builderEstatesRadius}px !important;`
                style.innerText +=
                    `-moz-border-right-left-radius: ${builderEstatesRadius}px !important;`
                style.innerText +=
                    `-moz-border-right-right-radius: ${builderEstatesRadius}px !important;`
            } else {
                style.innerText +=
                    `border-radius: 0px;`
            }
            if ( builderEstatesShadow==1 ){
                style.innerText += `box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);-webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);-moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);`
            } else {
                style.innerText += `box-shadow: none;-webkit-box-shadow: none;-moz-box-shadow: none;`
            }
            if ( builderEstatesBorder==1 ){
                style.innerText += `border: 1px solid #eeeeee;`
            } else {
                style.innerText += `border: none;`
            }
            style.innerText +=
                `}`


            style.innerText +=
                `.Properties.Properties-Thumb.Properties-Border.Properties-Shadow .Properties__gallery,
                .Properties.Properties-Thumb.Properties-Border.Properties-Shadow .Properties__gallery > div {`

            style.innerText +=
                `overflow: hidden !important;`
            if ( builderEstatesRadius!=null ){
                style.innerText +=
                    `border-top-left-radius: ${builderEstatesRadius}px !important;`
                style.innerText +=
                    `-moz-border-top-left-radius: ${builderEstatesRadius}px !important;`
                style.innerText +=
                    `-moz-border-top-right-radius: ${builderEstatesRadius}px !important;`

                style.innerText +=
                    `border-top-right-radius: ${builderEstatesRadius}px !important;`
                style.innerText +=
                    `-moz-border-right-left-radius: ${builderEstatesRadius}px !important;`
                style.innerText +=
                    `-moz-border-right-right-radius: ${builderEstatesRadius}px !important;`
            } else {
                style.innerText +=
                    `border-radius: 0px;`
            }
            style.innerText +=
                `}`
            // border-radius: ${builderEstatesRadius}px;box-shadow: ${builderEstatesShadow};border: ${builderEstatesBorder}px solid #eeeeee`
        }

        if ( builderBookingBoxBorder!=null || builderBookingBoxRadius!=null || builderBookingBoxShadow!=null ) {
            style.innerText +=
                `.booking-orders-design-box {`

            if ( builderBookingBoxRadius!=null ){
                style.innerText +=
                    `border-radius: ${builderBookingBoxRadius}px;`
            } else {
                style.innerText +=
                    `border-radius: 0px;`
            }
            if ( builderBookingBoxShadow==1 ){
                style.innerText += `box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);-webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);-moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);`
            } else {
                style.innerText += `box-shadow: none;-webkit-box-shadow: none;-moz-box-shadow: none;`
            }
            if ( builderBookingBoxBorder==1 ){
                style.innerText += `border: 1px solid #eeeeee;`
            } else {
                style.innerText += `border: none;`
            }

            style.innerText +=
                `}`
            // border-radius: ${builderPropertiesRadius}px;box-shadow: ${builderPropertiesShadow};border: ${builderPropertiesBorder}px solid #eeeeee`
        }

        if ( builderPropertiesViewServicesBorder!=null || builderPropertiesViewServicesRadius!=null || builderPropertiesViewServicesShadow!=null ) {
            style.innerText +=
                `.service-item-design-box {`

            if ( builderPropertiesViewServicesRadius!=null ){
                style.innerText +=
                    `border-radius: ${builderPropertiesViewServicesRadius}px;`
            } else {
                style.innerText +=
                    `border-radius: 0px;`
            }
            if ( Checkers.isValidPositiveNumber(builderPropertiesViewServicesShadow) ){
                style.innerText += `box-shadow: 0 0 `+builderPropertiesViewServicesShadow+`px rgba(0, 0, 0, 0.25);-webkit-box-shadow: 0 0 `+builderPropertiesViewServicesShadow+`px rgba(0, 0, 0, 0.25);-moz-box-shadow: 0 0 `+builderPropertiesViewServicesShadow+`px rgba(0, 0, 0, 0.25);`
            } else {
                style.innerText += `box-shadow: none;-webkit-box-shadow: none;-moz-box-shadow: none;`
            }
            if ( builderPropertiesViewServicesBorder==1 ){
                style.innerText += `border: 1px solid #eeeeee;`
            } else {
                style.innerText += `border: none;`
            }

            style.innerText += `overflow: hidden;`
            style.innerText +=
                `}`
            // border-radius: ${builderPropertiesRadius}px;box-shadow: ${builderPropertiesShadow};border: ${builderPropertiesBorder}px solid #eeeeee`
        }

        if ( builderPropertiesBorder!=null || builderPropertiesRadius!=null || builderPropertiesShadow!=null ) {
            style.innerText +=
                `.design-box-service {`

            if ( builderPropertiesRadius!=null ){
                style.innerText +=
                    `border-radius: ${builderPropertiesRadius}px;`
            } else {
                style.innerText +=
                    `border-radius: 0px;`
            }
            if ( builderPropertiesShadow==1 ){
                style.innerText += `box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.25);-webkit-box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.25);-moz-box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.25);`
            } else {
                style.innerText += `box-shadow: none;-webkit-box-shadow: none;-moz-box-shadow: none;`
            }
            if ( builderPropertiesBorder==1 ){
                style.innerText += `border: 1px solid #eeeeee;`
            } else {
                style.innerText += `border: none;`
            }

            style.innerText +=
                `}`
            // border-radius: ${builderPropertiesRadius}px;box-shadow: ${builderPropertiesShadow};border: ${builderPropertiesBorder}px solid #eeeeee`
        }

        if ( builderDestinationsRadius!=null || builderDestinationsBorder!=null || builderDestinationsShadow!=null ) {
            style.innerText +=
                `.design-box-destination {`

            if ( builderDestinationsRadius!=null ){
                style.innerText +=
                    `border-radius: ${builderDestinationsRadius}px;`
            } else {
                style.innerText +=
                    `border-radius: 0px;`
            }
            if ( builderDestinationsShadow==1 ){
                style.innerText += `box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.25);-webkit-box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.25);-moz-box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.25);`
            } else {
                style.innerText += `box-shadow: none;-webkit-box-shadow: none;-moz-box-shadow: none;`
            }
            if ( builderDestinationsBorder==1 ){
                style.innerText += `border: 1px solid #eeeeee;`
            } else {
                style.innerText += `border: none;`
            }

            style.innerText +=
                `}`
            // border-radius: ${builderPropertiesRadius}px;box-shadow: ${builderPropertiesShadow};border: ${builderPropertiesBorder}px solid #eeeeee`
        }

        if ( builderCollectionsShadow!=null || builderCollectionsBorder!=null || builderCollectionsRadius!=null ) {
            style.innerText +=
                `.design-box-collection, .design-box-collection .card { overflow: hidden; `

            if ( builderCollectionsRadius!=null ){
                style.innerText +=
                    `border-radius: ${builderCollectionsRadius}px;`
            } else {
                style.innerText +=
                    `border-radius: 0px;`
            }
            if ( builderCollectionsShadow==1 ){
                style.innerText += `box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.25);-webkit-box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.25);-moz-box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.25);`
            } else {
                style.innerText += `box-shadow: none;-webkit-box-shadow: none;-moz-box-shadow: none;`
            }
            if ( builderCollectionsBorder==1 ){
                style.innerText += `border: 1px solid #eeeeee;`
            } else {
                style.innerText += `border: none;`
            }

            style.innerText +=
                `}`
            // border-radius: ${builderPropertiesRadius}px;box-shadow: ${builderPropertiesShadow};border: ${builderPropertiesBorder}px solid #eeeeee`
        }

        if ( builderExperiencesRadious!=null || builderExperiencesShadow!=null || builderExperiencesBorder!=null ) {
            style.innerText +=
                `.experience-design-box { overflow: hidden; `

            if ( builderExperiencesRadious!=null ){
                style.innerText +=
                    `border-radius: ${builderExperiencesRadious}px;`
            } else {
                style.innerText +=
                    `border-radius: 0px;`
            }
            if ( Checkers.isValidPositiveNumber(builderExperiencesShadow) ){
                style.innerText += `box-shadow: 0px 0px `+builderExperiencesShadow+`px -5px rgba(0, 0, 0, 0.25) !important;`
                style.innerText += `-webkit-box-shadow: 0px 0px `+builderExperiencesShadow+`px -5px rgba(0, 0, 0, 0.25) !important;`
                style.innerText += `-moz-box-shadow: 0px 0px `+builderExperiencesShadow+`px -5px rgba(0, 0, 0, 0.25) !important;`
            } else {
                style.innerText += `box-shadow: none;-webkit-box-shadow: none;-moz-box-shadow: none;`
            }
            if ( builderExperiencesBorder==1 ){
                style.innerText += `border: 1px solid #eeeeee;`
            } else {
                style.innerText += `border: none;`
            }

            style.innerText +=
                `}`
            // border-radius: ${builderPropertiesRadius}px;box-shadow: ${builderPropertiesShadow};border: ${builderPropertiesBorder}px solid #eeeeee`
        }

        // builderMenuSpacing
        style.innerText +=
            `.navbar-nav > li > a { `

        if (builderMenuSpacing=='default') {
            style.innerText +=
                `padding: 10px 12px `
        } else if (builderMenuSpacing=='comfortable') {
            style.innerText +=
                `padding: 10px 15px `
        } else if (builderMenuSpacing=='compact') {
            style.innerText +=
                `padding: 10px 9px `
        }

        style.innerText +=
            `}`

        style.innerText += '.service-item-homepage {'
        style.innerText += `box-shadow: 0px 0px `+servicesHomepageShadow+`px -5px rgba(0, 0, 0, 0.25);-webkit-box-shadow: 0px 0px `+servicesHomepageShadow+`px -5px rgba(0, 0, 0, 0.25);-moz-box-shadow: 0px 0px `+servicesHomepageShadow+`px -5px rgba(0, 0, 0, 0.25);`
        style.innerText += `border-radius: ${servicesHomepageRadious}px !important;`
        style.innerText += `overflow: hidden;`
        style.innerText += '}'

        style.innerText +=
            `.nav-tabs-fillup > li > a:after { background-color: rgb(`+rgbDesignColor+`); border: none; }`

        style.innerText +=
            `.nav-tabs-fillup > li > a.active > span.main-design-color { color: rgb(255, 255, 255) !important; }`

        if (disableUiControls) {
            style.innerText +=
                `#map .gm-style { pointer-events: none !important; }`
        }

        if (menuIconBorder) {
            style.innerText +=
                `.Header.Header-Dark-Transparent .navbar-toggle { border: 2px solid #ffffff !important; }`
            style.innerText +=
                `.Header.Header-Dark-Transparent .navbar-toggle svg { margin-top: 2px; }`
            style.innerText +=
                `.Header.Header-Light .navbar-toggle svg { margin-top: 2px; }`
            style.innerText +=
                `.Header.Header-Light .navbar-toggle { border: 2px solid `+headerMenuColor+` !important; }`
        }
        style.innerText +=
            customStyles

        // head.appendChild(style);

        // console.log("style.innerText", style.innerText)

        return style.innerText
    }

    // useEffect(() => {
    //     initialiazeStyle()
    // });


    return (
        <>
            <style dangerouslySetInnerHTML={{ __html: initialiazeStyle() }} />
        </>
    )
}

export default InlineStyles;
